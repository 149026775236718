import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ScrollBtn from "../components/scroll-btn"
import ReactMarkdown from "react-markdown"
import Header from "../components/header"
import Footer from "../components/footer"

import { graphql } from "gatsby"
import { rewriteSlug } from "../utils/rewriteSlug"
import ArticleCard from "../components/article-card"
import { useEffect } from "react"
import { isBrowser } from "../utils/isBrowser"
import { scrollToSection } from "../utils/scrollToSection"

const BlogPage = props => {
  const { Description, LoadMoreButton, Title, locale } =
    props.data.strapiBlogPage

  const articles = props.data.allStrapiArticle.edges
  const [items, setItems] = React.useState(9)
  const step = 6
  const [articlesArray, setProjectsArray] = React.useState([])

  const loadMoreProjects = () => {
    setItems(items + step)
  }

  useEffect(() => {
    setProjectsArray(articles.slice(0, 9))
  }, [setProjectsArray, articles])

  useEffect(() => {
    if (items > 9) {
      setProjectsArray(articles.slice(0, items))
    }
  }, [items, articles])

  useEffect(() => {
    if (isBrowser()) {
      const sections = document.querySelectorAll(
        "#gatsby-focus-wrapper section"
      )
      let currentSection = 0

      window.addEventListener("keyup", initScroller, true)

      function initScroller(event) {
        event.preventDefault()
        event.stopPropagation()

        if (event.code === "ArrowDown") {
          if (currentSection < sections.length - 1)
            currentSection = currentSection + 1

          scrollToSection(sections, currentSection)
        }
        if (event.code === "ArrowUp") {
          if (currentSection > 0) currentSection = currentSection - 1

          scrollToSection(sections, currentSection)
        }
      }

      return function cleanup() {
        window.removeEventListener("keyup", initScroller, true)
      }
    }
  })

  return (
    <>
      <Header
        locale={locale}
        siteTitle={props.data.site.siteMetadata?.title || `Title`}
        data={props.data.allStrapiHeader.nodes.find(
          header => header.locale === props.pageContext.locale
        )}
        localizations={
          props.data.strapiBlogPage.localizations.data[0].attributes
        }
        contactSlug={props.data.strapiContact.slug}
        currentPage={props.data.strapiBlogPage.slug}
      />
      <Layout>
        <Seo description={Description.data.Description} title={Title} />
        <section className="single-page__hero flex flex-col justify-center lg:min-h-[720px] px-6 relative">
          <div className="text-center my-8">
            <div>
              <div className="hero-circles-container">
                <div className="circle circle--dashed circle--1 circle--1-dashed"></div>
                <div className="circle circle--dashed circle--2 circle--2-dashed"></div>
                <div className="circle circle--dashed circle--3 circle--3-dashed"></div>
                <div className="circle circle--dashed circle--4 circle--4-dashed"></div>
              </div>
            </div>
            <h1 className="single-page__hero-title">{Title}</h1>

            <div className="single-page__hero-text">
              <ReactMarkdown>{Description.data.Description}</ReactMarkdown>
            </div>

            <div className="category-list">
              <Link
                to={"/" + rewriteSlug(props.data.strapiBlogPage.slug)}
                id={"category-list__item-0"}
                className="category-list__item is-active"
              >
                {props.data.strapiGeneralTranslation.AllArticles}
              </Link>
              {props.data.allStrapiCategory.edges.map((category, index) => {
                const data = category.node

                return (
                  <Link
                    to={
                      "/" +
                      rewriteSlug(data.slug, props.data.strapiBlogPage.slug)
                    }
                    id={"category-list__item-" + (index + 1)}
                    key={index}
                    className="category-list__item"
                  >
                    {data.name}
                  </Link>
                )
              })}
            </div>
          </div>
          <div className="scrollButtonContainer">
            <ScrollBtn
              beforeText={
                props.data.strapiGeneralTranslation.ScrollDownOrJustUse
              }
              afterText={props.data.strapiGeneralTranslation.Keys}
            />
          </div>
        </section>

        <section className="articles-list articles-list--featured">
          {articlesArray.slice(0, 1).map((article, index) => {
            const data = article.node

            return (
              <ArticleCard
                byText={props.data.strapiGeneralTranslation.By}
                key={index}
                data={data}
                parent={props.data.strapiBlogPage.slug}
                isFeatured={true}
              />
            )
          })}
        </section>
        <section className="articles-list">
          {articlesArray.slice(1, 3).map((article, index) => {
            const data = article.node

            return (
              <ArticleCard
                byText={props.data.strapiGeneralTranslation.By}
                key={index}
                data={data}
                parent={props.data.strapiBlogPage.slug}
                isBig={true}
              />
            )
          })}
        </section>
        <section className="articles-list">
          {articlesArray.slice(3).map((article, index) => {
            const data = article.node

            return (
              <ArticleCard
                byText={props.data.strapiGeneralTranslation.By}
                key={index}
                data={data}
                parent={props.data.strapiBlogPage.slug}
              />
            )
          })}
        </section>
        {items < articles.length && (
          <div className="projects-load-more-wrapper">
            <button
              className="inline-block font-headline font-extrabold text-base lg:text-xl uppercase no-underline rounded-full bg-gradient-to-r from-maroon to-orange-red text-white lg:m-0 py-4 px-7 hover:from-shiraz hover:to-shiraz"
              onClick={loadMoreProjects}
            >
              {LoadMoreButton}
            </button>
          </div>
        )}
      </Layout>
      <Footer
        contactSlug={props.data.strapiContact.slug}
        locale={locale}
        socialLinks={props.data.strapiGeneralSetting.SocialLinks}
        data={props.data.allStrapiFooter.nodes.find(
          header => header.locale === props.pageContext.locale
        )}
      />
    </>
  )
}

export const query = graphql`
  query ($locale: String) {
    site {
      siteMetadata {
        title
      }
    }

    strapiContact(locale: { eq: $locale }) {
      slug
    }

    strapiBlogPage(locale: { eq: $locale }) {
      slug
      locale
      Description {
        data {
          Description
        }
      }
      LoadMoreButton
      Title
      localizations {
        data {
          attributes {
            slug
            locale
          }
        }
      }
    }

    allStrapiCategory(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          name
          slug
        }
      }
    }

    allStrapiArticle(
      sort: { fields: createdAt, order: DESC }
      filter: { locale: { eq: $locale } }
    ) {
      edges {
        node {
          Description {
            data {
              Description
            }
          }
          author {
            name
            avatar {
              url
            }
          }
          blocks {
            ... on STRAPI__COMPONENT_SHARED_MEDIA {
              id
            }
            ... on STRAPI__COMPONENT_SHARED_QUOTE {
              id
            }
            ... on STRAPI__COMPONENT_SHARED_RICH_TEXT {
              id
            }
          }
          category {
            name
          }
          cover {
            url
          }
          publishedAt
          title
          slug
        }
      }
    }

    allStrapiFooter {
      nodes {
        locale
        ContactText
        Heading
        RightsClaim
        SocialHeading
        Text
        Secondary {
          Link
          Text
        }
        Location {
          Heading
          Text {
            data {
              Text
            }
          }
        }
        Footer {
          Link
          Text
        }
      }
    }

    allStrapiHeader {
      nodes {
        locale
        MobileContactButtonText
        MobileContactHeading
        MobileContactText
        Links {
          Link
          Text
        }
        ContactText
      }
    }

    strapiGeneralSetting(locale: { eq: $locale }) {
      ContactSection {
        Headline
        TextPlainHTML {
          data {
            TextPlainHTML
          }
        }
      }
      SocialLinks {
        Facebook
        LinkedIn
        Twitter
      }
      SocialShareLinks {
        Facebook
        LinkedIn
        Twitter
      }
    }
    strapiGeneralTranslation(locale: { eq: $locale }) {
      AllRightsReserved
      BackToBlog
      By
      Client
      CopiedToClipboard
      Keys
      ReadMore
      ScrollDownOrJustUse
      ViewProject
      AllArticles
    }
  }
`

export default BlogPage
